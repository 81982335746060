import {
  FieldErrorsImpl,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";

import {
  FormData,
  FormGroup,
  FormSubGroup,
  FormTitle,
} from "../form/Form/Form";
import { InputText } from "../form/InputText/InputText";
import { Offer } from "../hooks/useOfferQuery";
import { Select } from "../form/Select/Select";

import { validations } from "../validates/validates";
import { handleZipSearch } from "../../utils/zipCodeSearch";

type Props = {
  register: UseFormRegister<Offer>;
  errors: FieldErrorsImpl;
  prefectures: {
    text: string;
    value: string;
    index: number;
  }[];
  setValue: UseFormSetValue<Offer>;
};

export function BaseInputForm({
  register,
  errors,
  prefectures,
  setValue,
}: Props) {
  return (
    <>
      <FormGroup>
        <FormTitle title="姓" />
        <FormData>
          <InputText
            name="name1"
            placeholder="山田"
            maxLength={14}
            register={register("name1", { ...validations.required })}
            error={errors.name1}
          />
        </FormData>
      </FormGroup>
      <FormGroup>
        <FormTitle title="名" />
        <FormData>
          <InputText
            name="name2"
            placeholder="太郎"
            maxLength={14}
            register={register("name2", { ...validations.required })}
            error={errors.name2}
          />
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle title="セイ" />
        <FormData>
          <InputText
            name="name_kana1"
            placeholder="ヤマダ"
            maxLength={14}
            register={register("name_kana1", {
              ...validations.required,
              ...validations.kana,
            })}
            error={errors.name_kana1}
          />
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle title="メイ" />
        <FormData>
          <InputText
            name="name_kana2"
            placeholder="タロウ"
            maxLength={14}
            register={register("name_kana2", {
              ...validations.required,
              ...validations.kana,
            })}
            error={errors.name_kana2}
          />
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle title="郵便番号" />
        <FormData>
          <InputText
            name="zip"
            placeholder="0000000"
            maxLength={7}
            subText={`※7桁の半角数字で入力してください\n※7桁以下の場合は先頭に0を追加してください\n※ハイフンなしで入力してください`}
            register={register("zip", {
              ...validations.required,
              ...validations.halfNumber,
            })}
            error={errors.zip}
            onChange={(e) =>
              handleZipSearch<Offer, keyof Offer>(
                e.target.value,
                prefectures,
                setValue,
                "pref",
                "addr"
              )
            }
          />
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle title="都道府県" />
        <FormData>
          <Select
            name="pref"
            options={prefectures ?? []}
            register={register("pref", { ...validations.required })}
            error={errors.pref}
          />
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle title="市区町村" />
        <FormData>
          <InputText
            name="addr"
            placeholder=" "
            maxLength={50}
            register={register("addr", { ...validations.required })}
            error={errors.addr}
          />
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle title="番地" />
        <FormData>
          <InputText
            name="addr_other"
            placeholder=" "
            maxLength={50}
            register={register("addr_other", { ...validations.required })}
            error={errors.addr_other}
          />
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle title="建物名・部屋番号" required={false} />
        <FormData>
          <InputText
            name="addr_ap"
            placeholder=" "
            maxLength={50}
            register={register("addr_ap")}
            error={errors.addr_ap}
          />
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle
          title="電話番号"
          text="携帯電話か固定電話のどちらかが必須となります"
        />
        <FormData>
          <FormSubGroup subTitle="携帯電話番号">
            <InputText
              name="phone"
              type="tel"
              placeholder="08012345678"
              maxLength={11}
              subText={`※ハイフンなしで入力してください\n※半角数字で入力してください`}
              register={register("phone", { ...validations.halfNumber })}
              error={errors.phone}
            />
          </FormSubGroup>
          <FormSubGroup subTitle="固定電話番号">
            <InputText
              name="tel"
              type="tel"
              placeholder="0012345678"
              maxLength={10}
              subText={`※市外局番から入力してください\n※ハイフンなしで入力してください\n※半角数字で入力してください`}
              register={register("tel", { ...validations.halfNumber })}
              error={errors.tel}
            />
          </FormSubGroup>
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle title="FAX" required={false} />
        <FormData>
          <InputText
            name="fax"
            type="tel"
            placeholder="0012345678"
            maxLength={11}
            subText={`※ハイフンなしで入力してください\n※半角数字で入力してください`}
            register={register("fax", { ...validations.halfNumber })}
            error={errors.fax}
          />
        </FormData>
      </FormGroup>

      <FormGroup>
        <FormTitle title="メールアドレス" />
        <FormData>
          <InputText
            type="email"
            name="email"
            placeholder="example@example.com"
            maxLength={255}
            register={register("email", { ...validations.required })}
            error={errors.email}
          />
        </FormData>
      </FormGroup>
    </>
  );
}
