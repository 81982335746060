// layout
import { SiteLayout } from "../../layout/SiteLayout/SiteLayout";

// common
import { Heading1 } from "../../common/Heading/Heading";
import { Text } from "../../common/Text/Text";
import { Button, ButtonBox } from "../../common/Button/Button";

type InquiryCompleteProps = {
  refleshInquiry?: () => void;
  // eslint-disable-next-line react/no-unused-prop-types
  error?: {
    type: string;
  };
};

// TODO src/page/inquiry/complete.tsx に移行する
export function InquiryComplete({ refleshInquiry }: InquiryCompleteProps) {
  return (
    <SiteLayout refleshInquiry={refleshInquiry}>
      <Heading1>
        お問い合わせ
        <br className="isOnlySp" />
        送信完了
      </Heading1>
      <Text>
        お問い合わせ内容の送信が完了しました。
        <br />
        ご登録のメールアドレスに確認用のメールを送信しています。
      </Text>

      <ButtonBox marginTop="narrow">
        <Button as="a" href="/">
          マイページトップへ
        </Button>
      </ButtonBox>
    </SiteLayout>
  );
}
