import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef, useState, useMemo, useContext } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import BaseApiV2 from "../../api/BaseApiV2";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1 } from "../../components/common/Heading/Heading";
import { Inner } from "../../components/common/Inner/Inner";
import { Text } from "../../components/common/Text/Text";
import { validations } from "../../components/validates/validates";
import {
  FormData,
  FormGroup,
  FormTitle,
} from "../../components/form/Form/Form";
import formStyle from "../../components/form/Form/Form.module.scss";
import { CheckBox } from "../../components/form/CheckBox/CheckBox";
import { useLoginCheck } from "../../components/hooks/useLoginCheck";
import { useSession } from "../../components/hooks/useSession";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";
import { InputText } from "../../components/form/InputText/InputText";
import { Select } from "../../components/form/Select/Select";
import { RefundAccount } from "../../components/types/userCar";
import { SelectedUserCarIdContext } from "../../components/providers/SelectedUserCarIdProvider";
import {
  useUserCars,
  invalidateUserCar,
} from "../../components/hooks/useUserCars";
import style from "./useinfo.module.scss";
import { displayLedgerPdf } from "../../utils/displayLedgerPdf";
import { useUserMaintenanceList } from "../../components/hooks/useUserMaintenance";

export default function AppiledLeaveout() {
  useLoginCheck();
  const session = useSession();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const loading = useRef(false);
  const loadingPage = useRef(false);
  const loadingSubmit = useRef(false);
  const { userCarId } = useParams<{ userCarId: string }>();
  const [leaveoutPayment, setLeaveoutPayment] = useState<number>(0);
  const maintenanceResults = useUserMaintenanceList(userCarId);
  // ロゴ表示用
  const { setSelectedUserCarId } = useContext(SelectedUserCarIdContext);
  const { userCars } = useUserCars();
  useEffect(() => {
    if (userCarId) {
      setSelectedUserCarId(userCarId);
    }
  }, [setSelectedUserCarId, userCarId]);
  const userCar = userCars.find((uc) => uc.value === userCarId);
  const headers = useMemo(
    () => ({
      "access-token": session?.accessToken || "",
      client: session?.client || "",
      uid: session?.uid || "",
    }),
    [session]
  );

  const displayLedger = async () => {
    if (userCarId && session !== null) {
      await displayLedgerPdf(false, userCarId, session);
    }
  };

  // 解約時に顧客へ返金が発生するかどうか
  useEffect(() => {
    if (loading.current) return;
    loading.current = true;
    BaseApiV2.post<{ data: { leaveout_payment: string } }>(
      "user_car/applied_leaveout",
      { user_car_id: userCarId },
      headers
    )
      .then((res) => {
        loadingPage.current = true;
        // 返金金額をセット
        setLeaveoutPayment(Number(res.data.leaveout_payment));
      })
      .catch((err) => {
        if (err.response.status === 404) {
          toast(
            err.response?.data?.message || "該当の車両が見つかりませんでした",
            { containerId: "error" }
          );
          navigate("/userinfo/summary");
        }
      });
  }, [navigate, headers, userCarId]);

  const {
    register,
    handleSubmit,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm<RefundAccount>({
    mode: "onChange",
  });

  const requiredChecked =
    watch("agree_user_policy") &&
    watch("agree_actual_expense") &&
    watch("agree_deposit_timing");

  const handleOnSubmit: SubmitHandler<RefundAccount> = (val: RefundAccount) => {
    // ボタン2度押し防止
    if (loadingSubmit.current) return;
    loadingSubmit.current = true;

    const params = {
      refund_account: {
        ...val,
      },
      leaveout_payment: leaveoutPayment,
      user_car_id: userCarId,
    };

    BaseApiV2.post<void>("user_car/create_leaveout", params, headers)
      .then(() => {
        clearErrors();
        loadingSubmit.current = false;
        toast("解約申請が完了しました。", { containerId: "success" });
        invalidateUserCar(queryClient);
        navigate("/userinfo/summary");
      })
      .catch((err) => {
        if (err.response.status === 400) {
          toast(err.response?.data?.message, { containerId: "error" });
        }
        for (const { name: attrName, type, message } of err?.response?.data ||
          []) {
          setError(
            attrName as keyof RefundAccount,
            { type, message },
            { shouldFocus: true }
          );
        }
        loadingSubmit.current = false;
      });
  };

  return loadingPage.current ? (
    <SiteLayout>
      <Heading1>解約申込</Heading1>
      <Inner>
        {userCar && (
          <>
            <div className={style.summary}>
              解約車両 {userCar.text}{" "}
              <button
                type="button"
                className={style.link_text}
                onClick={() => displayLedger()}
              >
                契約書
              </button>
              を確認する
              <br />
              解約金額 {leaveoutPayment.toLocaleString()}円
              {maintenanceResults && (
                <div className={style.maintenances}>
                  <Text marginBottom="narrow">【メンテナンス実施状況】</Text>
                  <table className={style.maintenance_table}>
                    <tr>
                      <th>作業(予定)日</th>
                      <th>メンテナンス状況</th>
                    </tr>
                    {Object.entries(maintenanceResults).map(
                      ([key, maintenance]) => (
                        <tr key={key}>
                          <td>{maintenance.working_date}</td>
                          <td>{maintenance.status}</td>
                        </tr>
                      )
                    )}
                  </table>
                </div>
              )}
            </div>
            <div className={style.summary}>
              解約する場合は
              <Link
                to={
                  (userCar.contract_type === "car_sensor" &&
                    "/contract?type=car_sensor") ||
                  "/contract"
                }
                target="_blank"
              >
                利用規約
              </Link>
              をご確認の上、解約申請してください。
              <br />
              なお解約払戻金が発生する場合、利用規約第８条２項の内容で計算されます。
            </div>
          </>
        )}
        <form
          className={formStyle.form}
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          {(leaveoutPayment > 0 && (
            <>
              <Text>～契約者ご本人の口座情報を入力して下さい～</Text>
              <FormGroup>
                <FormTitle title="銀行名" />
                <FormData>
                  <InputText
                    name="bank_name"
                    placeholder="銀行名"
                    maxLength={20}
                    register={register("bank_name", {
                      ...validations.required,
                    })}
                    error={errors.bank_name}
                  />
                </FormData>
              </FormGroup>
              <FormGroup>
                <FormTitle title="銀行コード" />
                <FormData>
                  <InputText
                    name="bank_code"
                    placeholder="1234"
                    maxLength={4}
                    register={register("bank_code", {
                      ...validations.required,
                      ...validations.halfNumber,
                    })}
                    error={errors.bank_code}
                  />
                </FormData>
              </FormGroup>
              <FormGroup>
                <FormTitle title="支店名" />
                <FormData>
                  <InputText
                    name="bank_branch_name"
                    placeholder="支店名"
                    maxLength={20}
                    register={register("bank_branch_name", {
                      ...validations.required,
                    })}
                    error={errors.bank_branch_name}
                  />
                </FormData>
              </FormGroup>
              <FormGroup>
                <FormTitle title="支店コード" />
                <FormData>
                  <InputText
                    name="bank_branch_code"
                    placeholder="123"
                    maxLength={3}
                    register={register("bank_branch_code", {
                      ...validations.required,
                      ...validations.halfNumber,
                    })}
                    error={errors.bank_branch_code}
                  />
                </FormData>
              </FormGroup>
              <FormGroup>
                <FormTitle title="口座種別" />
                <FormData>
                  <Select
                    name="bank_type"
                    options={[
                      { index: 1, value: "1", text: "普通" },
                      { index: 2, value: "2", text: "当座" },
                    ]}
                    register={register("bank_type", {
                      ...validations.required,
                    })}
                    error={errors.bank_type}
                  />
                </FormData>
              </FormGroup>
              <FormGroup>
                <FormTitle title="口座番号" />
                <FormData>
                  <InputText
                    name="bank_number"
                    placeholder="1234567"
                    maxLength={7}
                    register={register("bank_number", {
                      ...validations.required,
                      ...validations.halfNumber,
                    })}
                    error={errors.bank_number}
                  />
                </FormData>
              </FormGroup>
              <FormGroup>
                <FormTitle title="口座名義" />
                <FormData>
                  <InputText
                    name="bank_meigi"
                    placeholder="コウザメイギニンメイ"
                    maxLength={50}
                    register={register("bank_meigi", {
                      ...validations.required,
                    })}
                    error={errors.bank_meigi}
                  />
                </FormData>
              </FormGroup>
              <FormGroup>
                <FormData>
                  <ul className={formStyle.list}>
                    <CheckBox
                      label="インボイス対応の必要な方はお知らせください"
                      register={register("supported_invoice")}
                      error={errors.supported_invoice}
                    />
                  </ul>
                </FormData>
              </FormGroup>
            </>
          )) ||
            (leaveoutPayment === 0 && (
              <Text>以下のボタンを押して解約申請を完了してください。</Text>
            ))}
          <FormGroup>
            <FormData>
              <ul className={formStyle.list}>
                <CheckBox
                  label="利用規約を確認しました"
                  register={register("agree_user_policy")}
                  error={errors.agree_user_policy}
                />
              </ul>
            </FormData>
          </FormGroup>
          <FormGroup>
            <FormData>
              <ul className={formStyle.list}>
                <CheckBox
                  label="解約申込後のメンテナンス点検は全て実費になります"
                  register={register("agree_actual_expense")}
                  error={errors.agree_actual_expense}
                />
              </ul>
            </FormData>
          </FormGroup>
          <FormGroup>
            <FormData>
              <ul className={formStyle.list}>
                <CheckBox
                  label="解約払戻金が発生する場合の入金タイミングは末締め翌月末で振り込みとなります"
                  register={register("agree_deposit_timing")}
                  error={errors.agree_deposit_timing}
                />
              </ul>
            </FormData>
          </FormGroup>
          <ButtonBox>
            <Button type="submit" disabled={!requiredChecked} disabledColor>
              解約申請する
            </Button>
          </ButtonBox>
        </form>
        <ButtonBox>
          <Button as="a" href="/userinfo/summary" color="whiteBase">
            会員情報に戻る
          </Button>
        </ButtonBox>
      </Inner>
    </SiteLayout>
  ) : null;
}
