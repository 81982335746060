import axios from "axios";
import { UseFormSetValue, Path, PathValue } from "react-hook-form";

type ApiResponse = {
  results: {
    address1: string;
    address2: string;
    address3: string;
  }[];
};

type PrefTypes = {
  text: string;
  value: string;
  index: number;
}[];

const zipCodeApi = "https://zipcloud.ibsnet.co.jp/api/search";

export const handleZipSearch = async <T, K extends Path<T>>(
  zip: string,
  prefectures: PrefTypes,
  setValue: UseFormSetValue<T>,
  pref: K,
  addr: K
) => {
  // APIの仕様上、zipは7桁でなければreturn
  if (zip.length !== 7) return;

  const res = await axios.get<ApiResponse>(zipCodeApi, {
    params: {
      zipcode: zip,
    },
  });
  if (res.data.results) {
    const { address1, address2, address3 } = res.data.results[0];
    const prefecture = prefectures.find((p) => p.value === address1);
    if (prefecture) {
      setValue(pref, prefecture.value as PathValue<T, K>);
      setValue(addr, `${address2}${address3}` as PathValue<T, K>);
    }
  }
};
