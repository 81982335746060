// node_modules
import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

// 型import
import { UserCar } from "../types/userCar";
import { useSession } from "./useSession";

const url = `${process.env.REACT_APP_API_V2_BASE_URL}/mypage`;

type ApiResponse = {
  name: string;
  user_cars: {
    user_car_id: number;
    vehicle_type: string;
    leaveout_flg: boolean;
    contract_type: string;
    created_at: string;
    applied_leaveout_date: Date | null;
  }[];
  maintenances: {
    m_date: string;
    detail: string;
    maintenance_shop: {
      name: string;
      address: string;
      tel: string;
    };
  } | null;
};

const useUserCarsQuery = () => {
  const session = useSession();
  const navigate = useNavigate();

  const fetcher = async () => {
    const res = await axios.get<ApiResponse>(url, {
      headers: {
        "access-token": session?.accessToken || "",
        client: session?.client || "",
        uid: session?.uid || "",
      },
    });
    if (res.status === 401) {
      navigate("/login");
    } else if (res.status === 500) {
      throw new Error("500");
    }
    return res.data;
  };
  return useQuery<ApiResponse, AxiosError>([url], fetcher, {
    enabled: session != null,
    staleTime: 60 * 60 * 1000,
  });
};

export const useUserCars = () => {
  const query = useUserCarsQuery();
  const { data } = query;

  const { userCars, name } = useMemo(() => {
    if (!data) return { userCars: [], name: "" };
    const newUserCars: UserCar[] = data.user_cars.map((uc) => {
      return {
        text: uc.vehicle_type,
        value: uc.user_car_id.toString(),
        index: uc.user_car_id,
        contract_type: uc.contract_type,
        leaveout_flg: uc.leaveout_flg,
        applied_leaveout_date: uc.applied_leaveout_date,
      };
    });
    return { userCars: newUserCars, name: data.name };
  }, [data]);

  // 車両のuser_car_id
  const [carId, setCarId] = useState(
    userCars.length > 0 ? userCars[0].value : ""
  );

  // 車両の解約フラグ
  const [isLeaveout, setIsLeaveout] = useState(false);

  useEffect(() => {
    // 初期値の設定（API通信後に設定）
    const id = userCars.length > 0 ? userCars[0].value : "";
    setCarId(id);
    const selected = data?.user_cars.find(
      (uc) => uc.user_car_id.toString() === id
    );
    setIsLeaveout(selected ? !!selected.leaveout_flg : false);
  }, [JSON.stringify(userCars)]);

  // 車両情報プルダウン操作に使う関数
  const handleUserCars = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const select = data?.user_cars.find(
      (uc) => uc.user_car_id.toString() === e.target.value
    );
    setCarId(e.target.value);
    setIsLeaveout(select ? !!select.leaveout_flg : false);
  };

  return { carId, userCars, name, isLeaveout, handleUserCars, data, query };
};

export const useResetUserCars = () => {
  const queryClient = useQueryClient();
  const session = useSession();

  useEffect(() => {
    if (session == null) {
      queryClient.resetQueries({ queryKey: [url] });
    }
  }, [queryClient, session]);
};

export const invalidateUserCar = (c: QueryClient) => {
  c.invalidateQueries([url]);
};
