// node_modules
import { useQuery, QueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import moment from "moment";
import { AsYouType } from "libphonenumber-js";
import { useMemo } from "react";
import BaseApiV2 from "../../api/BaseApiV2";

import { useSession } from "./useSession";

type ApiResponse = {
  next_maintenance: {
    maintenance_result_id: string;
    m_date: string | null;
    m_plan: string;
    detail: string;
    status: string;
    schedule_datetime1: string;
    schedule_datetime2: string;
    schedule_datetime3: string;
    schedule_date1: string;
    schedule_date2: string;
    schedule_date3: string;
    schedule_time1: string;
    schedule_time2: string;
    schedule_time3: string;
    maintenance_shop: {
      name: string;
      address: string;
      tel: string;
      opening_hours: string;
      closing_hours: string;
    };
  } | null;
  after_next_maintenances: {
    m_date: string | null;
    m_plan: string;
    detail: string;
  }[];
  maintenance_histories: {
    m_date: string | null;
    m_plan: string;
    detail: string;
    status: string;
    remarks: string;
    maintenance_shop: { name: string };
    images: string[];
  }[];
};

type RetType = {
  nextMaintenance: {
    maintenance_result_id: string;
    date: string;
    detail: string;
    status: string;
    schedule_datetime1: string;
    schedule_datetime2: string;
    schedule_datetime3: string;
    schedule_date1: string;
    schedule_date2: string;
    schedule_date3: string;
    schedule_time1: string;
    schedule_time2: string;
    schedule_time3: string;
    maintenanceShop: {
      name: string;
      address: string;
      tel: string;
      openTime: string;
      closeTime: string;
    };
  } | null;
  afterNextMaintenances: {
    date: string;
    detail: string;
  }[];
  maintenanceHistories: {
    date: string;
    detail: string;
    status: string;
    remarks: string;
    maintenanceShopName: string;
    images: string[];
  }[];
};

type MaintenanceResult = {
  id: string;
  status: string;
  working_date: string;
};

type MaintenanceResultList = Record<string, MaintenanceResult>;

const url = `${process.env.REACT_APP_API_V2_BASE_URL}/maintenances`;

const useUserMaintenanceQuery = (carId: string) => {
  const session = useSession();
  const fetcher = async () => {
    if (!carId) return null;
    const res = await axios.get<ApiResponse>(url, {
      params: { user_car_id: carId },
      headers: {
        "access-token": session?.accessToken || "",
        client: session?.client || "",
        uid: session?.uid || "",
      },
    });
    return res.data;
  };
  return useQuery<ApiResponse | null, AxiosError>([url, carId], fetcher, {
    staleTime: 60 * 60 * 1000,
  });
};

const getMaintenanceDate = (date: string | null, plan: string) =>
  date
    ? moment(date).format("YYYY/MM/DD HH:mm")
    : moment(plan).format("YYYY/MM/DD");

const convertStatus = (status: string) => {
  switch (status) {
    case "not_yet":
    case "tentative":
      return "予定";
    case "in_reservation":
    case "ordered":
      return "予約中";
    case "in_work":
      return "作業中";
    default:
      throw new Error("不正なステータス");
  }
};

const convertType: (data: ApiResponse) => RetType = (data: ApiResponse) => {
  return {
    nextMaintenance: data.next_maintenance
      ? {
          maintenance_result_id: data.next_maintenance.maintenance_result_id,
          date: getMaintenanceDate(
            data.next_maintenance.m_date,
            data.next_maintenance.m_plan
          ),
          detail: data.next_maintenance.detail,
          status: convertStatus(data.next_maintenance.status),
          schedule_datetime1: data.next_maintenance.schedule_datetime1,
          schedule_datetime2: data.next_maintenance.schedule_datetime2,
          schedule_datetime3: data.next_maintenance.schedule_datetime3,
          schedule_date1: data.next_maintenance.schedule_date1,
          schedule_date2: data.next_maintenance.schedule_date2,
          schedule_date3: data.next_maintenance.schedule_date3,
          schedule_time1: data.next_maintenance.schedule_time1,
          schedule_time2: data.next_maintenance.schedule_time2,
          schedule_time3: data.next_maintenance.schedule_time3,
          maintenanceShop: {
            ...data.next_maintenance.maintenance_shop,
            tel: new AsYouType("JP").input(
              data.next_maintenance.maintenance_shop.tel
            ),
            openTime: data.next_maintenance.maintenance_shop.opening_hours,
            closeTime: data.next_maintenance.maintenance_shop.closing_hours,
          },
        }
      : null,
    afterNextMaintenances: data.after_next_maintenances.map((d) => ({
      date: getMaintenanceDate(d.m_date, d.m_plan),
      detail: d.detail,
    })),
    maintenanceHistories: data.maintenance_histories.map((d) => ({
      date: getMaintenanceDate(d.m_date, d.m_plan),
      detail: d.detail,
      status: d.status,
      remarks: d.remarks,
      maintenanceShopName: d.maintenance_shop.name,
      images: d.images,
    })),
  };
};

export const useUserMaintenance = (carId: string) => {
  const query = useUserMaintenanceQuery(carId);
  const res = useMemo(() => {
    const { data } = query;
    if (!data)
      return {
        maintenanceHistories: [],
        nextMaintenance: null,
        afterNextMaintenances: [],
      };

    return convertType(data);
  }, [query]);

  return { ...res, query };
};

const useUserMaintenanceListQuery = (carId: string | undefined) => {
  const session = useSession();
  const fetcher = async () => {
    if (!carId) return null;
    const res = await BaseApiV2.get<{ data: MaintenanceResultList }>(
      "/maintenances/list",
      { user_car_id: carId },
      {
        "access-token": session?.accessToken || "",
        client: session?.client || "",
        uid: session?.uid || "",
      }
    );
    return res.data;
  };
  return useQuery<MaintenanceResultList | null, AxiosError>(
    ["/maintenances/list", carId],
    fetcher,
    {
      staleTime: 60 * 60 * 1000,
    }
  );
};

export const useUserMaintenanceList = (carId: string | undefined) => {
  const query = useUserMaintenanceListQuery(carId);
  const res = useMemo(() => {
    const { data } = query;
    if (!data) return null;

    return data;
  }, [query]);

  return res;
};

export const invalidateUserMaintenance = (c: QueryClient) => {
  c.invalidateQueries([url]);
};
