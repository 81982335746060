import Axios, { AxiosRequestHeaders } from "axios";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_V2_BASE_URL,
});

/**
 * API Getメソッド
 *
 * レスポンスの型を指定したい場合はget<型>(path, params)とする
 * @param path APIのパス
 * @param params リクエストパラメータ
 * @headers リクエストヘッダー
 * @returns APIレスポンスを受け取れるPromise
 */
const get: <T>(
  path: string,
  params: unknown,
  headers?: AxiosRequestHeaders
) => Promise<T> = async (
  path: string,
  params: unknown,
  headers?: AxiosRequestHeaders
) => {
  return axios.get(path, {
    params,
    headers: headers || {},
    data: {},
  });
};

/**
 * API Postメソッド
 *
 * レスポンスの型を指定したい場合はget<型>(path, params)とする
 * @param path APIのパス
 * @param params リクエストパラメータ
 * @headers リクエストヘッダー
 * @returns APIレスポンスを受け取れるPromise
 */
const post: <T>(
  path: string,
  params: unknown,
  headers?: AxiosRequestHeaders
) => Promise<T> = async (
  path: string,
  params: unknown,
  headers?: AxiosRequestHeaders
) => {
  return axios.post(path, params, {
    headers: headers || {},
  });
};

/**
 * API Putメソッド
 *
 * レスポンスの型を指定したい場合はget<型>(path, params)とする
 * @param path APIのパス
 * @param params リクエストパラメータ
 * @headers リクエストヘッダー
 * @returns APIレスポンスを受け取れるPromise
 */
const put: <T>(
  path: string,
  params: unknown,
  headers?: AxiosRequestHeaders
) => Promise<T> = async (
  path: string,
  params: unknown,
  headers?: AxiosRequestHeaders
) => {
  return axios.put(path, params, {
    headers: headers || {},
  });
};

export default { get, post, put };
