import clsx from "clsx";
// style
import style from "./contract.module.scss";
// common
import { Heading3 } from "../../components/common/Heading/Heading";

export function PremiumContents() {
  return (
    <div className={clsx(style.contract)}>
      <h1>カープレミア安心メンテパック利用規約</h1>

      <div className={style.info}>
        <p className={style.content}>
          ※契約者の方へ
          <div className={style.halfSpace} />
          事前に内容をご確認いただき、本規約の各条項が契約内容となることをご了承いただいた上で、お申込みください。
        </p>
        <p className={style.content}>
          申込者（以下「甲」という）は、プレミアワランティサービス株式会社（以下「乙」という）が提供するカープレミア安心メンテパック（以下「本サービス」という）について、次の事項を確認します。
        </p>
      </div>

      <div className={style.rule}>
        <Heading3>第1条（申込条件）</Heading3>
        <p>本商品の申込は、下記の条件を全て満たす場合のみ有効とすします。</p>
        <ul>
          <li>
            甲が本サービスの付帯を希望する車両（以下「契約車両」という）を特定のうえ、乙または乙の代理店に対し申し込むものとします。
          </li>
          <li>
            国内の自動車メーカー「トヨタ・ニッサン・ホンダ・マツダ・ミツビシ・スバル・スズキ・ダイハツ・いすゞ」が製造し、国内正規新車販売特約店（新車ディーラー）を通じ販売された車両であること。
          </li>
          <li>
            以下に定める車種に該当しないこと。（MIRAI、センチュリー、LX、LS、LC、レクサスFシリーズ、GTR、プレジデント、シーマ、NSX、RX-8、クラリティFUEL
            CELL、及び新車時の車両本体価格が700万円（税抜）を超える高額車両。）
          </li>
          <li>輸入車両でないこと。（並行輸入を含む）</li>
          <li>
            ガソリン、ディーゼル、ハイブリッド（プラグイン含む）であること。（天然ガス、LPG、電気自動車、水素自動車、燃料電池自動車は不可）
          </li>
          <li>
            年式が車検証の初年度登録年月より15年経過未満（179か月経過以下）の車両であること。ただし軽自動車の場合は1年プランに限り20年経過未満（239か月経過以下）、普通車の場合は１年プランに限り18年経過未満（215か月以下）であること。
          </li>
          <li>
            累積の総走行距離が、150,000km以下の車両であること。（メーターの交換歴がある車両の場合は、交換前の走行距離を総和した総走行距離で計算を行う。）
          </li>
          <li>冠水歴のある車両でないこと。</li>
          <li>
            車両の用途が自家用登録の車両であること。（法人名義、業務利用目的は可）
          </li>
          <li>第4条にて定める改造車両でないこと。</li>
          <li>
            契約者は車検証の使用者ご本人であること。（ご家族でも契約不可）
          </li>
          <li>
            契約者が販売店の代表者、代表者の2親等以内の親族、又は従業員等でないこと。
          </li>
          <li>
            契約者が暴力団、暴力団員、暴力団準構成員、暴力団関係企業、又はその他前述事項に準ずる者でないこと。
          </li>
          <li>
            その他、当社が個別に判断した特殊な車種や事案に該当する車両。（2・8ナンバー又はリース、レンタカー車両は不可）
          </li>
        </ul>
      </div>

      <div className={style.rule}>
        <Heading3>第2条（メンテナンスの流れ）</Heading3>
        <ul>
          <li>
            マイページに記載されているメンテナンス基準日をご確認ください。
          </li>
          <li>
            メンテナンス基準日に合わせて指定された整備工場へ予約し入庫日を確定させてください。予約が可能な期間は第7条4項で定める期間とします。
          </li>
          <li>
            メンテナンス当日に予約した整備工場へメンテナンスする車両をお持ち込みください。
            <br />
            メンテナンス項目に含まれない作業が発生した場合は、ご契約者様が整備実施の有無を判断してください。
          </li>
        </ul>
      </div>

      <div className={clsx(style.rule, style.noneList)}>
        <Heading3>第3条（変更の届出）</Heading3>
        <ul>
          <li>
            甲は、乙への届出事項（住所、連絡先等）に変更が生じたときには、遅滞なく乙に届け出なければならないものとします。
          </li>
        </ul>
      </div>

      <div className={style.rule}>
        <Heading3>第4条（改造車両の取扱い）</Heading3>
        <p>
          本条各号の「改造車両の」に該当する車両の保証申込はできないものとする。
        </p>
        <ul>
          <li>
            燃料コントローラー・加給圧（ブースト）コントローラー・インタークーラー・インジェクター・ウエストゲートバルブ・タービン・スーパーチャージャー・キャブレター・カムシャフト・カムシャフトプーリー・コンピューター・ロールバー・エアサスペンション・エアサスペンションコントローラーが車両製造会社の付設部品以外への交換・付設、又は交換・付設歴が確認された車両。
          </li>
          <li>
            ハイドロリクスサスペンション・ハイルーフ・ボディーリフトアップ・シフト変更・エンジンボアアップの加工、又は加工歴が確認された車両。
          </li>
          <li>違法な改造が施された車両。</li>
          <li>車検証登録型式上に「改」表記がある車両。</li>
        </ul>
      </div>

      <div className={clsx(style.rule, style.noneList)}>
        <Heading3>第5条 （契約期間）</Heading3>
        <ul>
          <li>
            契約期間は、車検の残日数により異なります。マイページより契約期間をご覧ください。
          </li>
        </ul>
      </div>

      <div className={style.rule}>
        <Heading3>第6条（属人性）</Heading3>
        <ul>
          <li>
            甲は、本サービスに基づく契約上の地位及び本サービスを受ける権利（以下「受益権」という）を、第三者に移転してはならないものとします。
          </li>
          <li>
            受益権を有する者が、本サービスの対象となる車両の使用者又は所有者のいずれにも該当しないこととなったときには、受益権は自動的に消滅するものとします。
          </li>
        </ul>
      </div>

      <div className={style.rule}>
        <Heading3>第7条（適用範囲）</Heading3>
        <ul>
          <li>
            本サービスに含まれる整備項目は別紙のメンテナンスパックプランをご確認ください。
          </li>
          <li>
            本サービスを実施する場合は、お客様自身でメンテナンス実施予定の整備工場へのお車の持ち込み、お引き取りをお願いします。
          </li>
          <li>本サービス実施期間中の代車及びレンタカー費用は含まれません。</li>
          <li>
            本サービスの実施期間は、各メンテナンス基準日の1ヶ月前から、当該基準日の3ヶ月後までとなります。なお、有効期間経過後は整備を受ける権利を喪失するものとし、また未実施の整備相当額の返還はいたしません。
          </li>
          <li>
            本サービスでは、中途解約金以外の返金はできませんのでご了承ください。
          </li>
          <li>
            本サービスから除外される作業は以下の項目となります。
            <br />
            車検諸費用(自賠責保険料・重量税・印紙代)、ご契約いただいたメンテナンスパック以外の修理費用、事故修理費用、任意保険料、税金等の費用
          </li>
        </ul>
      </div>

      <div className={style.rule}>
        <Heading3>第8条（本メンテナンスパック契約の解除）</Heading3>
        <ul>
          <li>
            甲は、いつでも中途解約できるものとします。ただし、個別クレジット契約やローン契約に本サービスの代金が含まれており、かつ個別クレジットやローン契約の契約期間中の場合は、中途解約はできません。
          </li>
          <li>
            前項による中途解約の場合、乙は甲に対し下記の計算式により中途解約金を算出し返金いたします。返金が発生する場合はご契約者様ご本人の口座以外は認められません。なお、下記計算式の手数料に満たない不足分が発生した場合はご請求させて頂きます。
            <br />
            <small>
              解約金(注1)＝(メンテナンスパック料金－解約時点での実施済の整備費用－契約時の加入手数料)×80%
              <br />
              注1：20%については、解約手数料とします。
            </small>
          </li>
          <li>
            乙が下記のいずれかに該当する事実を認めた場合に、乙は、甲との間の本サービスに関する契約を即座に解除することができるものとし、解除による返金はいたしません。
            <ol>
              <li>甲が本サービスを悪用したと乙が認めたとき。</li>
              <li>その他甲が本規約に違反した場合。</li>
            </ol>
          </li>
        </ul>
      </div>

      <div className={style.rule}>
        <Heading3>第9条 （本規約の変更）</Heading3>
        <ul>
          <li>
            <div className={style.twoLevel}>
              乙は、次の各号に該当する場合には、あらかじめ、効力発生日を定め、本規約を変更する旨、変更後の内容及び効力発生時期を、乙のホームページにおいて公表するほか、必要があるときにはその他相当な方法で甲に周知した上で、本規約を変更することができるものとします。
              <ol>
                <li>変更の内容が甲の一般の利益に適合する場合。</li>
                <li>
                  変更の内容が本規約に係る取引の目的に反せず、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らし、合理的なものである場合。
                </li>
              </ol>
            </div>
          </li>
          <li>
            乙は、前項に基づくほか、あらかじめ変更後の内容を乙のホームページにおいて公表する方法又は乙から甲に通知する方法（必要があるときにはその他相当な方法を含む）により甲に周知した上で、本規約を変更することができるものとします。この場合、当該周知の後に甲が本サービスの適用により車両の修理を求めることにより、変更後の内容に対する承諾の意思表示を行うものとし、当該意思表示をもって本規約が変更されるものとします。
          </li>
        </ul>
      </div>

      <div className={style.rule}>
        <Heading3>第10条 （個人情報の取扱いについて）</Heading3>
        <ul>
          <li>
            甲は、本規約及び乙の開示するプライバシーポリシーに記載する内容を、あらかじめ、確認のうえで本サービスの申込みを行うものとします。尚、プライバシーポリシーは乙のサイトに記載します。
            <br />（
            <a
              href="https://www.premium-group.co.jp/companyinfo/pws/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.premium-group.co.jp/companyinfo/pws/privacy/
            </a>
            ）
          </li>
          <li>
            <div className={style.threeLevel}>
              甲は、個人情報を乙のグループ会社との間で共同利用することを確認するとともに同意します。
              <ol className={style.TwoLev}>
                <li>共同利用する個人情報</li>
                <ol className={style.ThreeLev}>
                  <li>
                    所定の申込書に甲等が記載した氏名、住所、電話番号、メールアドレス、その他乙への届出事項等の甲の属性情報。
                  </li>
                  <li>
                    本契約に関する申込日、商品名、車両の車種、車台番号、登録年月日、走行距離、その他車検証等に記載される情報等の車両情報。
                  </li>
                </ol>
                <li>共同利用する者の範囲</li>
                <ol className={style.ThreeLev}>
                  <li>
                    プレミアグループ株式会社の有価証券報告書等に記載するプレミアグループ株式会社の連結子会社及び持分法適用関連会社及び連結子会社の子会社｡
                  </li>
                  <li>
                    <p>
                      プレミアグループ株式会社の連結子会社の子会社を含むプレミアグループ各企業｡対象企業については､プレミアグループ株式会社のサイトに記載します。
                      <br />（
                      <a
                        href="https://www.premium-group.co.jp/privacy/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.premium-group.co.jp/privacy/
                      </a>
                      ）
                    </p>
                  </li>
                </ol>
                <li>主な利用目的</li>
                <ol className={style.ThreeLev}>
                  <li>本サービスに付随、関連する業務の運営及び管理。</li>
                  <li>
                    サービスの向上を目的としたデータの集計と統計的データの把握。
                  </li>
                  <li>甲等からの問合せ事項、要望事項への回答等。</li>
                  <li>
                    新商品のお知らせ、関連するアフターサービス、宣伝物及び印刷物の送付、営業案内等。
                  </li>
                </ol>
              </ol>
            </div>
          </li>
          <li>
            甲は、乙が本サービスに関する、第一次的な苦情の受付及び処理、並びに個人データの内容等について開示、訂正、利用停止等の権限を有する事業者とする事に同意するものとします。
          </li>
          <li>
            甲は、乙が守秘義務契約、及び個人情報の取扱いに関する規定を含む業務委託契約を締結した業務委託会社（整備・修理工場、ロードサービス事業者、等）に対し、本サービスに付随する業務の遂行に必要な範囲において、個人情報の取扱いの一部又は全部を委託する場合があるものとします。
          </li>
          <li>
            甲からの、個人情報の取扱いに関する問合せ先は以下のとおりとします。
            <br />
            <span className={style.callCenter}>
              プレミアワランティサービス株式会社 お客様相談室
              <br />
              〒105-0001 東京都港区虎ノ門二丁目10番4号
              オークラプレステージタワー
              <br />
              電話番号：03-6842-3732
            </span>
          </li>
        </ul>
      </div>

      <div className={style.rule}>
        <Heading3>第11条 （反社会的勢力の排除）</Heading3>
        <ul>
          <li>
            <div className={style.twoLevel}>
              甲は、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ、特殊知能暴力集団等又はテロリスト等（疑いがある場合を含む。）、その他これらに準ずる者（以下これらを「暴力団員等」という。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約します。
              <ol>
                <li>
                  暴力団員等が経営を支配していると認められる関係を有すること。
                </li>
                <li>
                  暴力団員等が経営に実質的に関与していると認められる関係を有すること。
                </li>
                <li>
                  自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってするなど、不当に暴力団員等を利用していると認められる関係を有すること。
                </li>
                <li>
                  暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認められる関係を有すること。
                </li>
                <li>
                  役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること。
                </li>
              </ol>
            </div>
          </li>
          <li>
            <div className={style.twoLevel}>
              甲は、自ら又は第三者を利用して次の各号の一にでも該当する行為を行わないことを確約します。
              <ol>
                <li>暴力的な要求行為。</li>
                <li>法的な責任を超えた不当な要求行為。</li>
                <li>取引に関して、脅迫的な言動をし、又は暴力を用いる行為。</li>
                <li>
                  風説を流布し、偽計を用い又は威力を用いて乙の信用を毀損し、又は乙の業務を妨害する行為。
                </li>
                <li>その他前各号に準ずる行為。</li>
              </ol>
            </div>
          </li>
          <li>
            甲が暴力団員等若しくは第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、又は第1項の規定に基づく表明確約に関して虚偽の申告をしたことが判明し、乙との取引を継続することが不適切である場合には、甲は、乙から請求があり次第、乙に対する一切の債務の期限の利益を失い、直ちに債務を弁済するものとします。又、この場合、乙は、催告その他何らの手続を要することなく、甲との契約の全部又は一部を解除することができるものとする。尚、甲及び乙は、契約の解除に起因し又は関連して甲に損害等が生じた場合であっても、乙が何ら責任を負うものではないことを確認します。
          </li>
        </ul>

        <div className={clsx(style.rule, style.noneList)}>
          <Heading3>第12条 （管轄裁判所）</Heading3>
          <ul>
            <li>
              本サービスの契約に関し、紛争が生じた場合、訴訟の必要があるときは、訴額に応じ、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とし、調停の必要があるときは東京簡易裁判所を専属的合意管轄裁判所とします。
            </li>
          </ul>
        </div>

        <div className={style.rule}>
          <Heading3>第13条 （ご注意事項）</Heading3>
          <ul>
            <li>
              メンテナンスを実施する場合は、第7条4項に定める期間内で必ずご予約してください。
            </li>
            <li>
              甲からのご用命等の追加整備費用は別途申し受けます。また追加整備につきましては都度精算とさせていただきます。
            </li>
            <li>本契約は甲との契約車両以外ご使用できません。</li>
            <li>
              メンテナンス時期のご案内のため、転居等の場合は、必ず住所等を連絡ください。
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
