import { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1 } from "../../components/common/Heading/Heading";
import { Inner } from "../../components/common/Inner/Inner";
import { Text } from "../../components/common/Text/Text";
import { ProxyInputAgreement } from "../../components/register/ProxyInputAgreement";
import { BaseInputForm } from "../../components/register/BaseInputForm";
import { validations } from "../../components/validates/validates";
import {
  ErrorList,
  FormData,
  FormGroup,
  FormTitle,
} from "../../components/form/Form/Form";
import formStyle from "../../components/form/Form/Form.module.scss";
import { InputRadioList } from "../../components/form/InputRadio/InputRadio";
import { CheckBox } from "../../components/form/CheckBox/CheckBox";
import {
  Offer,
  useOfferQuery,
  useUpdateOfferMutation,
} from "../../components/hooks/useOfferQuery";
import {
  OfferSession,
  useOfferSession,
} from "../../components/hooks/useOfferSession";
import { usePaymentTypes } from "../../components/hooks/usePaymentTypeQuery";
import { transitGmoSiteByUid } from "../../components/hooks/useGmoQuery";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";
import { usePrefectureOptions } from "../../components/hooks/usePrefecturesQuery";

const useOffer = (
  session: OfferSession | null,
  options?: Parameters<typeof useOfferQuery>[1]
) => {
  const query = useOfferQuery(session, { staleTime: Infinity, ...options });
  return { query, offer: query.data };
};

export default function RegisterEdit() {
  const session = useOfferSession();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState<boolean>(true);
  const contactType = useOffer(session).offer?.contract_type;
  const { paymentTypes } = usePaymentTypes();
  const mutation = useUpdateOfferMutation();
  const { prefectures } = usePrefectureOptions();

  // メッセージを出しつつログインページに戻る
  const goToLogin = useCallback(
    (message: string) => {
      toast(message, { containerId: "error" });
      navigate(`/register/login/${id}`);
    },
    [id, navigate]
  );

  const sessionTimedOut = () => goToLogin("セッションが切れました");

  // react-hook-formの定義
  const {
    register,
    handleSubmit,
    reset,
    setError,
    watch,
    setValue,
    formState: { isDirty, errors },
  } = useForm<Offer>({
    mode: "onChange",
    defaultValues: {
      name1: "",
      name2: "",
      name_kana1: "",
      name_kana2: "",
      phone: "",
      tel: "",
      fax: "",
      zip: "",
      pref: "",
      addr: "",
      addr_other: "",
      addr_ap: "",
      email: "",
      payment_type: "",
      r_policy_flg: false,
      p_policy_flg: false,
      user_policy_flg: false,
      proxy_input_agree_flag: false,
    },
  });

  // 初期値のセット
  const [fetchOffer, setFetchOffer] = useState({});
  useEffect(() => {
    if (prefectures) {
      reset(fetchOffer);
    }
  }, [fetchOffer, prefectures, reset]);

  const premiumPolicyFlg = watch("p_policy_flg");
  const proxyInputAgreeFlag = watch("proxy_input_agree_flag");
  const userPolicyFlag = watch("user_policy_flg");

  useOffer(session, {
    onSuccess(data) {
      if (data) {
        if (data.status !== "in_offer") {
          navigate(`/register/complete?status=success&uid=${id}`);
        }
        setFetchOffer(data);
        setLoading(false);
      }
    },
    onError(error) {
      if (
        !axios.isAxiosError(error) || // 非 Axios エラー
        error.response?.status !== 401 // 500 等
      ) {
        console.error(error);
        goToLogin("システムエラーが発生しました");
        return;
      }
      sessionTimedOut();
    },
  });

  const loadingRef = useRef(false);
  useEffect(() => {
    if ((!session || session.uid !== id) && !loadingRef.current) {
      loadingRef.current = true;
      goToLogin("不正なアクセスです。");
    }
  }, [goToLogin, id, session, loadingRef]);

  if (!session || session.uid !== id) {
    return null;
  }

  const onSubmit: Parameters<typeof handleSubmit>[0] = (tempValues) => {
    let values = tempValues;
    // 電話番号のバリデーション
    if (
      telOrPhoneValidation(values.tel, values.phone) ||
      telorPhoneLengthValidation("tel", values.tel) ||
      telorPhoneLengthValidation("phone", values.phone)
    ) {
      return null;
    }
    // プレミア社契約は「現金一括」のみ
    if (contactType === "car_premium") {
      values = { ...tempValues, payment_type: "cash" };
    }
    if (mutation.isLoading) return;
    mutation.mutate(
      { session, values },
      {
        onSuccess() {
          if (values.payment_type === "credit") {
            transitGmoSiteByUid(id); // GMOカード登録画面へ遷移させる
          } else {
            toast("更新しました", { containerId: "success" });
            navigate(`/register/complete?status=success&uid=${id}`);
          }
        },
        onError(error) {
          if (
            !axios.isAxiosError(error) || // 非 Axios エラー
            !error.response?.data // 500 等
          ) {
            console.error(error);
            toast("システムエラーが発生しました", { containerId: "error" });
            return;
          }
          // 認証エラー
          if (error.response.status === 401) {
            sessionTimedOut();
            return;
          }
          // バリデーションエラー
          for (const { name, type, message } of error.response.data || []) {
            setError(
              name as keyof Offer,
              { type, message },
              { shouldFocus: true }
            );
          }
        },
      }
    );
  };

  const columnNames = {
    name1: "姓",
    name2: "名",
    name_kana1: "セイ",
    name_kana2: "メイ",
    zip: "郵便番号",
    pref: "都道府県",
    addr: "市区町村",
    addr_other: "番地",
    phone: "携帯電話番号",
    tel: "固定電話番号",
    fax: "FAX",
    addr_ap: "建物名",
    email: "メールアドレス",
    payment_type: "支払い方法",
    r_policy_flg: "リクルート プライバシーポリシー",
    p_policy_flg: "プレミア プライバシーポリシー",
    user_policy_flg: "メンテパック利用規約",
    proxy_input_agree_flag: "代理入力規約",
  };

  const telOrPhoneValidation = (
    tel: Offer["tel"],
    phone: Offer["phone"]
  ): boolean => {
    if (!tel && !phone) {
      setError(
        "tel",
        {
          type: "custom",
          message: "携帯電話か固定電話のどちらかが必須となります",
        },
        { shouldFocus: true }
      );
      setError(
        "phone",
        {
          type: "custom",
          message: "携帯電話か固定電話のどちらかが必須となります",
        },
        { shouldFocus: true }
      );
      return true;
    }
    return false;
  };

  const telorPhoneLengthValidation = (
    type: string,
    num: Offer["tel"] | Offer["phone"]
  ): boolean => {
    if (num && (String(num).length < 10 || String(num).length > 11)) {
      setError(
        type as keyof Offer,
        {
          type: "custom",
          message: "10桁もしくは11桁の半角数字でご入力ください",
        },
        { shouldFocus: true }
      );
      return true;
    }
    return false;
  };

  return (
    <SiteLayout>
      {!loading && (
        <>
          <Heading1>申し込み情報入力</Heading1>
          <Text>お客様情報のご入力をお願いいたします</Text>
          <Inner>
            <form className={formStyle.form} onSubmit={handleSubmit(onSubmit)}>
              <ErrorList errors={errors} columnNames={columnNames} />
              <BaseInputForm
                register={register}
                errors={errors}
                prefectures={prefectures}
                setValue={setValue}
              />
              {(contactType === "car_premium" && (
                <>
                  <ProxyInputAgreement />
                  <FormGroup>
                    <FormTitle title="" /> {/* 必須マークを表示用 */}
                    <div
                      style={{
                        width: "70%",
                        margin: "0 auto",
                        minWidth: "270px",
                        marginTop: "-1rem",
                      }}
                    >
                      <CheckBox
                        label="上記、代理入力規約に同意します"
                        register={register("proxy_input_agree_flag", {
                          ...validations.notChecked,
                        })}
                        error={errors.proxy_input_agree_flag}
                      />
                    </div>
                  </FormGroup>
                </>
              )) ||
                (contactType === "car_sensor" && (
                  <>
                    <FormGroup>
                      <FormTitle title="支払い方法" />
                      {paymentTypes.length > 0 && (
                        <FormData>
                          <ul className={formStyle.list}>
                            <InputRadioList
                              radioLists={paymentTypes}
                              register={register("payment_type", {
                                ...validations.required,
                              })}
                              error={errors.payment_type}
                            />
                          </ul>
                        </FormData>
                      )}
                    </FormGroup>
                    {/* <FormGroup> 一旦、コメントアウト
                      <FormTitle title="" required={false} />
                      <FormData>
                        <ul className={formStyle.list}>
                          {recruitUrl && (
                            <CheckBox
                              label={
                                <>
                                  <a
                                    href={recruitUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    リクルート プライバシーポリシー
                                  </a>
                                  に同意します
                                </>
                              }
                              register={register("r_policy_flg")}
                              error={errors.r_policy_flg}
                            />
                          )}
                        </ul>
                      </FormData>
                    </FormGroup> */}
                  </>
                ))}
              <FormGroup>
                <FormTitle title="" /> {/* 必須マークを表示用 */}
                <FormData>
                  <ul className={formStyle.list}>
                    <CheckBox
                      label={
                        <>
                          <a
                            href="https://www.premium-group.co.jp/companyinfo/pws/privacy/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            プレミア プライバシーポリシー
                          </a>
                          に同意します
                        </>
                      }
                      register={register("p_policy_flg", {
                        ...validations.notChecked,
                      })}
                      error={errors.p_policy_flg}
                    />
                  </ul>
                </FormData>
              </FormGroup>
              <FormGroup>
                <FormTitle title="" /> {/* 必須マークを表示用 */}
                <FormData>
                  <ul className={formStyle.list}>
                    <CheckBox
                      label={
                        <Link
                          to={
                            (contactType === "car_sensor" &&
                              "/contract?type=car_sensor") ||
                            "/contract"
                          }
                          style={{ textDecoration: "underline" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {(contactType === "car_sensor" &&
                            "カーセンサーメンテナンスパック利用規約") ||
                            "カープレミア安心メンテパック利用規約"}
                        </Link>
                      }
                      register={register("user_policy_flg", {
                        ...validations.notChecked,
                      })}
                      error={errors.user_policy_flg}
                    />
                  </ul>
                </FormData>
              </FormGroup>
              <ButtonBox>
                <Button
                  type="submit"
                  disabled={
                    !isDirty ||
                    !premiumPolicyFlg ||
                    !userPolicyFlag ||
                    (contactType === "car_premium" && !proxyInputAgreeFlag)
                  }
                  disabledColor
                >
                  登録する
                </Button>
              </ButtonBox>
            </form>
          </Inner>
        </>
      )}
    </SiteLayout>
  );
}
