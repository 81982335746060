import { Key, ReactNode } from "react";

// style
import clsx from "clsx";
import { DeepRequired, FieldErrorsImpl } from "react-hook-form";
import style from "./Form.module.scss";
import { Offer } from "../../hooks/useOfferQuery";

// plugin

type Props = {
  children: ReactNode;
};

export function Form({ children }: Props) {
  return <form className={style.form}>{children}</form>;
}

type FormGroup = Props & {
  disable?: boolean;
};

export function FormGroup({ children, disable = false }: FormGroup) {
  return (
    <div className={clsx(style.group, disable && style.disable)}>
      {children}
    </div>
  );
}

type FormSubGroup = Props & {
  subTitle: string;
};

export function FormSubGroup({ children, subTitle }: FormSubGroup) {
  return (
    <div className={style.subGroup}>
      <div className={style.subTitle}>{subTitle}</div>
      {children}
    </div>
  );
}

type TitleProps = {
  title: string;
  label?: boolean;
  required?: boolean;
  text?: string;
};

export function FormTitle({
  title,
  label = true,
  required = true,
  text,
}: TitleProps) {
  return (
    <>
      <div className={clsx(style.wrapper, label && style.isLabel)}>
        <div className={style.title}>{title}</div>
        {label &&
          (required ? (
            <span className={clsx(style.label, style.required)}>必須</span>
          ) : (
            <span className={clsx(style.label, style.elective)}>任意</span>
          ))}
      </div>

      {text && <p className={style.text}>{text}</p>}
    </>
  );
}

export function FormData({ children }: Props) {
  return <div className={style.data}>{children}</div>;
}

type ErrorTextProps = {
  columnNames: {
    [key: string]: string;
  };
  errors: FieldErrorsImpl<DeepRequired<Offer>>;
};

export function ErrorList({ errors, columnNames }: ErrorTextProps) {
  return (
    <div>
      {Object.keys(errors).length > 0 && (
        <div>
          <ul>
            <p className={style.errorTitle}>
              入力に不備がありました。再度ご確認お願いします。
            </p>
            {Object.keys(columnNames).map((key: Key) => {
              if (errors[key as keyof Offer]) {
                return (
                  <li
                    key={key}
                    className={style.errorText}
                    style={{ listStyle: "none" }}
                  >
                    {columnNames[key as keyof typeof columnNames]}：
                    {errors[key as keyof Offer]?.message}
                  </li>
                );
              }
              return null;
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
