import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// layout
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";

// common
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1 } from "../../components/common/Heading/Heading";
import { Inner } from "../../components/common/Inner/Inner";
import { Text } from "../../components/common/Text/Text";

// form
import {
  FormData,
  FormGroup,
  FormTitle,
} from "../../components/form/Form/Form";
import { InputText } from "../../components/form/InputText/InputText";
import { Select } from "../../components/form/Select/Select";
import { Textarea } from "../../components/form/Textarea/Textarea";

// style
import formStyle from "../../components/form/Form/Form.module.scss";

import { useInquiryDataQuery } from "../../components/hooks/useInquiryDataQuery";
import { InquiryComplete } from "../../components/page/InquiryComplete/InquiryComplete";
import { useLoginCheck } from "../../components/hooks/useLoginCheck";
import { useSession } from "../../components/hooks/useSession";
import { ServerError } from "../error/500";

// カスタムhooks
import { useUserCars } from "../../components/hooks/useUserCars";

// Provider
import { SelectedUserCarIdContext } from "../../components/providers/SelectedUserCarIdProvider";

const API_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL;

function InquiryEdit() {
  // ログインチェック
  useLoginCheck();
  const navigate = useNavigate();

  const session = useSession();

  // 車両情報
  const { carId, handleUserCars } = useUserCars();

  // コンテキストに選択されたcar_idを保存
  const { setSelectedUserCarId } = useContext(SelectedUserCarIdContext);
  useEffect(() => setSelectedUserCarId(carId), [carId, setSelectedUserCarId]);

  // 登録情報取得
  const { data, error } = useInquiryDataQuery(session);
  // お問い合わせ項目
  const inquiryList = useMemo(() => {
    if (!data) return [];
    const keys = Object.keys(data?.subjects);
    return keys.map((key) => {
      const val = data?.subjects[key];
      return {
        text: val,
        value: key,
        index: parseInt(key, 10),
      };
    });
  }, [data]);
  // ユーザー名、EMail
  const { name, email } = useMemo(
    () => ({
      name: data?.name,
      email: data?.email,
    }),
    [data]
  );
  // 車両情報 userCars: Select用, userCarData: 車両データ
  const { userCars, userCarData } = useMemo(
    () => ({
      userCars: data?.user_cars.map((uc) => ({
        text: uc.vehicle_type,
        value: uc.user_car_id.toString(),
        index: uc.user_car_id,
      })),
      userCarData: data?.user_cars,
    }),
    [data]
  );

  // 車両ナンバー
  const [carNumber, setCarNumber] = useState(
    (userCars &&
      userCars.length > 0 &&
      userCarData?.find(
        (car) => car.user_car_id.toString() === userCars[0].value
      )?.number) ||
      ""
  );

  const [completed, setCompleted] = useState(false);

  const loading = useRef(false);

  type ValuesType = {
    subject: string;
    detail: string;
    user_car: string;
    email: string;
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<ValuesType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const resetForm = useCallback(() => {
    reset({
      subject: inquiryList.length > 0 ? inquiryList[0].value : "",
      detail: "",
      user_car: userCars && userCars.length > 0 ? userCars[0].value : "",
      email: email || "",
    });
  }, [reset]);

  useEffect(() => {
    // APIで取得したデータを初期値に入れる（email,detailはresetしない）
    reset({
      subject: inquiryList.length > 0 ? inquiryList[0].value : "",
      user_car: userCars && userCars.length > 0 ? userCars[0].value : "",
    });
    setCarNumber(
      (userCars &&
        userCars.length > 0 &&
        userCarData?.find(
          (car) => car.user_car_id.toString() === userCars[0].value
        )?.number) ||
        ""
    );
    // 選択肢の内容が変化した場合だけ
  }, [JSON.stringify(inquiryList), JSON.stringify(userCars)]);

  const handleOnSubmit: SubmitHandler<ValuesType> = (val: ValuesType) => {
    if (loading.current) return;
    loading.current = true;
    const baseURL = `${API_BASE_URL}/inquiry`;
    const params = {
      user_car_id: val.user_car,
      subject: val.subject,
      detail: val.detail,
      email: val.email,
    };
    const headers = {
      "access-token": session?.accessToken || "",
      client: session?.client || "",
      uid: session?.uid || "",
    };
    axios
      .post(baseURL, params, { headers })
      .then(() => {
        clearErrors();
        setCompleted(true);
        loading.current = false;
      })
      .catch((err) => {
        for (const { name: attrName, type, message } of err.response.data ||
          []) {
          setError(
            attrName as keyof ValuesType,
            { type, message },
            { shouldFocus: true }
          );
        }

        loading.current = false;
      });
  };

  if (error) {
    if (!axios.isAxiosError(error)) {
      return <ServerError />;
    }
    if (!error.response) {
      return <ServerError />;
    }
    if (error.response?.status === 401) {
      navigate("/login");
      return null;
    }
    if (error.response?.status === 500) {
      return <ServerError />;
    }
    return <ServerError />;
  }

  return completed ? (
    <InquiryComplete
      refleshInquiry={() => {
        resetForm();
        setCompleted(false);
      }}
    />
  ) : (
    <SiteLayout>
      <Heading1>お問い合わせ</Heading1>
      <Text>お問い合わせ内容を選択・入力し、送信ボタンを押してください</Text>

      <Inner>
        <form
          className={formStyle.form}
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <FormGroup>
            <FormTitle title="お問い合わせ項目" />
            <FormData>
              <Select
                name="subject"
                options={inquiryList}
                defaultValue={
                  inquiryList.length > 0 ? inquiryList[0].value : ""
                }
                register={register("subject", {
                  required: { value: true, message: "値が入力されていません" },
                })}
                error={errors.subject}
              />
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="お問い合わせ内容" />
            <FormData>
              <Textarea
                name="detail"
                placeholder="入力してください"
                register={register("detail", {
                  required: { value: true, message: "値が入力されていません" },
                })}
                error={errors.detail}
              />
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="連絡先メールアドレス" />
            <FormData>
              <InputText
                type="email"
                name="email"
                maxLength={255}
                defaultValue={email}
                placeholder="examlpe@ne.jp"
                register={register("email", {
                  required: { value: true, message: "値が入力されていません" },
                })}
                error={errors.email}
              />
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="氏名" label={false} />
            <FormData>{name}</FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="お問い合わせ車両" />
            <FormData>
              <Select
                name="user_car"
                options={userCars || []}
                defaultValue={
                  userCars && userCars.length > 0 ? userCars[0].value : ""
                }
                register={register("user_car", {
                  required: { value: true, message: "値が入力されていません" },
                })}
                error={errors.user_car}
                onChange={(e) => {
                  setCarNumber(
                    userCarData?.find(
                      (car) => car.user_car_id.toString() === e.target.value
                    )?.number || ""
                  );
                  handleUserCars(e);
                }}
              />
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="車両ナンバー" label={false} />
            <FormData>{carNumber}</FormData>
          </FormGroup>

          <ButtonBox>
            <Button type="submit">送信する</Button>
          </ButtonBox>
        </form>
      </Inner>
    </SiteLayout>
  );
}

export default InquiryEdit;
