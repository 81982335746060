import axios from "axios";
import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { Button, ButtonBox } from "../../components/common/Button/Button";
import { Heading1, Heading3 } from "../../components/common/Heading/Heading";
import { Inner } from "../../components/common/Inner/Inner";
import { Text } from "../../components/common/Text/Text";
import {
  Form,
  FormData,
  FormGroup,
  FormSubGroup,
  FormTitle,
} from "../../components/form/Form/Form";
import { useLoginCheck } from "../../components/hooks/useLoginCheck";
import { useProfileQuery, User } from "../../components/hooks/useProfileQuery";
import { useSession } from "../../components/hooks/useSession";
import { SiteLayout } from "../../components/layout/SiteLayout/SiteLayout";
import { ServerError } from "../error/500";

import { useUserCars } from "../../components/hooks/useUserCars";
import { MemberIdLink } from "../../components/mypage/MemberIdLink";

import style from "./useinfo.module.scss";

export default function Page() {
  useLoginCheck();
  const session = useSession();
  const navigate = useNavigate();
  const { data, error } = useProfileQuery(session);

  if (error) {
    console.error(error);
    // 謎のエラー
    if (!axios.isAxiosError(error)) {
      return <ServerError />;
    }
    // ネットワークエラー
    if (!error.response) {
      return <ServerError />;
    }
    // 認証エラー
    if (error.response.status === 401) {
      navigate("/login");
      return null;
    }
    // その他
    return <ServerError />;
  }
  if (!data) {
    return null;
  }
  return <UserinfoSummary user={data} />;
}

function UserinfoSummary({ user }: { user: User }) {
  const session = useSession();

  // 車両情報
  const { userCars } = useUserCars();

  if (!session) {
    return null;
  }

  return (
    <SiteLayout>
      <Heading1>会員情報</Heading1>
      <Text>
        会員情報を変更したい場合は、「編集」ボタンから登録情報を編集できます
      </Text>
      <Inner>
        <Form>
          <FormGroup disable={false}>
            <FormTitle title="氏名" label={false} />
            <FormData>
              {user.name1} {user.name2}（{user.name_kana1} {user.name_kana2}）
            </FormData>
          </FormGroup>

          <FormGroup>
            <FormTitle title="郵便番号" label={false} />
            <FormData>{user.zip}</FormData>
          </FormGroup>
          <FormGroup>
            <FormTitle title="住所" label={false} />
            <FormData>
              {user.pref}
              {user.addr}
              {user.addr_other}
              {user.addr_ap}
            </FormData>
          </FormGroup>
          <FormGroup>
            <FormTitle title="電話番号" text={undefined} label={false} />
            <FormData>
              {user.phone && (
                <FormSubGroup subTitle="携帯電話番号">
                  {user.phone}
                </FormSubGroup>
              )}
              {user.tel && (
                <FormSubGroup subTitle="固定電話番号">{user.tel}</FormSubGroup>
              )}
            </FormData>
          </FormGroup>
          <FormGroup>
            <FormTitle title="FAX" label={false} />
            <FormData>{user.fax}</FormData>
          </FormGroup>
          <FormGroup>
            <FormTitle title="メールアドレス" label={false} />
            <FormData>{user.email}</FormData>
            <Link to="/userinfo/email">メールアドレスの変更はこちら</Link>
          </FormGroup>
          <FormGroup>
            <FormTitle title="パスワード" label={false} />
            <FormData>※セキュリティ保護のため非表示にしています</FormData>
            <Link to="/userinfo/password">パスワードの変更はこちら</Link>
          </FormGroup>
          <ButtonBox>
            <Button as="a" href="edit">
              編集する
            </Button>
          </ButtonBox>
          <div className={style.space} />
          <Inner>
            <Heading3>会員証</Heading3>
            {userCars.map((car) => {
              return (
                <div key={car.value} style={{ textAlign: "center" }}>
                  <MemberIdLink
                    carId={car.value}
                    name={car.text}
                    leaveoutFlg={car.leaveout_flg}
                    key={car.value}
                    session={session}
                  />
                  {!car.leaveout_flg &&
                    ((!car.applied_leaveout_date && (
                      <Link
                        to={`/userinfo/applied_leaveout/${car.value}`}
                        className={clsx(style.link, style.home)}
                      >
                        {car.text} の解約申込をする
                      </Link>
                    )) || (
                      <p style={{ color: "red" }}>{car.text} の解約申請中</p>
                    ))}
                </div>
              );
            })}
          </Inner>

          <ButtonBox>
            <Button as="a" href="/" color="whiteBase">
              マイページトップに戻る
            </Button>
          </ButtonBox>
        </Form>
      </Inner>
    </SiteLayout>
  );
}
